import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  defaultView: 'productGrid',
  SearchRequestDefaults: {
    ...bigcommerceSparkDefaults.SearchRequestDefaults,
    pageSize: 12,
  },
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter(
      (w) =>
        !['SearchPage', 'SearchHeader', 'SearchBox', 'FitmentTableTabDesktop', 'FacetBrandTiles'].includes(
          w.name,
        ),
    ),
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'animate-loaded animated' },
      template: 'SearchPage',
    },
    {
      name: 'SearchBox',
      location: '#quickSearch',
      template: 'SearchBox',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'is_featured:desc': 'Featured Items',
        'date_created:desc': 'Newest Items',
        'total_sold:desc': 'Best Selling',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'By Review',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      sortSelectClass: 'form-select',
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: 'a.tab-title[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands',
      template: 'facetTiles/alphabeticalContainer',
      view: 'columns', // FIXME: 'grid' view not yet styled for the Spark theme
    },
  ],
};
